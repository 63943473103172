(function () {
    'use strict';

    angular.module('aerosApp')
        .factory('TestResultsService', TestResultsService);

    TestResultsService.$inject = ['aerosApi'];

    function TestResultsService(aerosApi) {
        var service = {
            refactorData: refactorData,
            getExistingSummaryResults: getExistingSummaryResults,
            getProjectInspectionStandards: getProjectInspectionStandards
        };

        // AEROS-1135 format data for re-designed OLTS screen
        function refactorData(data, testMethods) {
            var refactor = [];
            _.each(data, function (fg) {
                var hasFailedFiber = false;
                var hasIncompleteFiber = false;
                // re-factored object - start with existing FG props
                var o = {
                    id: fg.id,
                    uuid: fg.uuid,
                    name: fg.name,
                    fiberType: fg.fiberType,
                    mpoPolarity: fg.mpoPolarity,
                    endNameMain: fg.endNameMain,
                    endNameRemote: fg.endNameRemote,
                    fibers: [],
                    refFibers: []
                };
                // get testTypes from nested data
                o.has = {
                    testType: {
                        length: _.some(fg.testResults, function (tr) {
                            return !_.isEmpty(tr.lengthMeasurement);
                        }),
                        loss: _.some(fg.testResults, function (tr) {
                            return !_.isEmpty(tr.lossMeasurements);
                        }),
                        orl: _.some(fg.testResults, function (tr) {
                            return !_.isEmpty(tr.orlLossMeasurements);
                        }),
                        rloss: _.some(fg.referenceTestResult, function (tr) {
                            return !_.isEmpty(tr.referenceLossMeasurements);
                        }),
                        rorl: _.some(fg.referenceTestResult, function (tr) {
                            return !_.isEmpty(tr.referenceOrlLossMeasurements);
                        })
                    },
                    wavelength: {},
                    refWavelength: {}
                };
                // get wavelengths from nested data
                var wl = _.uniq(_.flatten(_.map(fg.testResults, function (tr) {
                    return _.union(_.keys(tr.lossMeasurements), _.keys(tr.orlLossMeasurements));
                })));
                // get specific test types per wavelength from nested data
                _.each(wl, function (w) {
                    o.has.wavelength[w] = {};
                    if (o.has.testType.loss) {
                        o.has.wavelength[w].lmtr = _.some(fg.testResults, function (tr) {
                            return !_.isEmpty(tr.lossMeasurements) && !_.isEmpty(tr.lossMeasurements[w]) && !_.isEmpty(tr.lossMeasurements[w].MainToRemote);
                        });
                        o.has.wavelength[w].lrtm = _.some(fg.testResults, function (tr) {
                            return !_.isEmpty(tr.lossMeasurements) && !_.isEmpty(tr.lossMeasurements[w]) && !_.isEmpty(tr.lossMeasurements[w].RemoteToMain);
                        });
                        o.has.wavelength[w].la = _.some(fg.testResults, function (tr) {
                            return !_.isEmpty(tr.lossMeasurements) && !_.isEmpty(tr.lossMeasurements[w]) && !_.isEmpty(tr.lossMeasurements[w].Average);
                        });
                    }
                    if (o.has.testType.orl) {
                        o.has.wavelength[w].omtr = _.some(fg.testResults, function (tr) {
                            return !_.isEmpty(tr.orlLossMeasurements) && !_.isEmpty(tr.orlLossMeasurements[w]) && !_.isEmpty(tr.orlLossMeasurements[w].MainToRemote);
                        });
                        o.has.wavelength[w].ortm = _.some(fg.testResults, function (tr) {
                            return !_.isEmpty(tr.orlLossMeasurements) && !_.isEmpty(tr.orlLossMeasurements[w]) && !_.isEmpty(tr.orlLossMeasurements[w].RemoteToMain);
                        });
                    }
                });
                // get specific test types at FG level from above (used to render fiber column headers across FG)
                o.has.testType.lmtr = _.some(o.has.wavelength, function (w) {
                    return w.lmtr;
                });
                o.has.testType.lrtm = _.some(o.has.wavelength, function (w) {
                    return w.lrtm;
                });
                o.has.testType.la = _.some(o.has.wavelength, function (w) {
                    return w.la;
                });
                o.has.testType.omtr = _.some(o.has.wavelength, function (w) {
                    return w.omtr;
                });
                o.has.testType.ortm = _.some(o.has.wavelength, function (w) {
                    return w.ortm;
                });
                _.each(fg.testResults, function (f) {
                    // fiber-level props
                    var p = {
                        fiberGroup: fg.name,
                        fiberId: f.fiberId,
                        sortIndex: f.sortIndex,
                        status: f.status,
                        failedInspection: f.failedInspection,
                        incompleteOLTS: f.incompleteOLTS,
                        failedLength: f.failedLength,
                        failedLoss: f.failedLoss,
                        failedORLLoss: f.failedORLLoss,
                        incomplete: f.incomplete
                    };
                    // cache existence of failed + incomplete fibers (used for FG status)
                    if (f.status === "Failed") {
                        hasFailedFiber = true;
                    } else if (f.status === "Incomplete") {
                        hasIncompleteFiber = true;
                    }
                    // length measurements
                    if (f.lengthMeasurement) {
                        p.length = f.lengthMeasurement.roundedLength;
                        p.lengthStatus = f.lengthMeasurement.status;
                    } else if (o.has.testType.length) {
                        p.lengthStatus = "Incomplete";
                    }
                    // populate status at all wavelengths found in FG
                    var q = {};
                    _.each(o.has.wavelength, function (fgResultsExist, wav) {
                        q[wav] = {
                            wavelength: wav.substr(1)
                        };
                        // loss measurements at a given wavelength
                        if (!_.isEmpty(f.lossMeasurements) && !_.isEmpty(f.lossMeasurements[wav]) && !_.isEmpty(f.lossMeasurements[wav].MainToRemote)) {
                            q[wav].lmtr = f.lossMeasurements[wav].MainToRemote.loss;
                            q[wav].lmtrs = f.lossMeasurements[wav].MainToRemote.status;
                            q[wav].lmtrn = f.lossMeasurements[wav].MainToRemote.loss < 0;
                        } else if (fgResultsExist.lmtr) {
                            q[wav].lmtrs = "Incomplete";
                        }
                        if (!_.isEmpty(f.lossMeasurements) && !_.isEmpty(f.lossMeasurements[wav]) && !_.isEmpty(f.lossMeasurements[wav].RemoteToMain)) {
                            q[wav].lrtm = f.lossMeasurements[wav].RemoteToMain.loss;
                            q[wav].lrtms = f.lossMeasurements[wav].RemoteToMain.status;
                            q[wav].lrtmn = f.lossMeasurements[wav].RemoteToMain.loss < 0;
                        } else if (fgResultsExist.lrtm) {
                            q[wav].lrtms = "Incomplete";
                        }
                        if (!_.isEmpty(f.lossMeasurements) && !_.isEmpty(f.lossMeasurements[wav]) && !_.isEmpty(f.lossMeasurements[wav].Average)) {
                            q[wav].la = f.lossMeasurements[wav].Average.loss;
                            q[wav].las = f.lossMeasurements[wav].Average.status;
                            q[wav].lan = f.lossMeasurements[wav].Average.loss < 0;
                        } else if (fgResultsExist.la) {
                            q[wav].las = "Incomplete";
                        }
                        // orl measurements at a given wavelength
                        if (!_.isEmpty(f.orlLossMeasurements) && !_.isEmpty(f.orlLossMeasurements[wav]) && !_.isEmpty(f.orlLossMeasurements[wav].MainToRemote)) {
                            q[wav].omtr = f.orlLossMeasurements[wav].MainToRemote.loss;
                            q[wav].omtrs = f.orlLossMeasurements[wav].MainToRemote.status;
                            q[wav].omtrn = f.orlLossMeasurements[wav].MainToRemote.loss < 0;
                        } else if (fgResultsExist.omtr) {
                            q[wav].omtrs = "Incomplete";
                        }
                        if (!_.isEmpty(f.orlLossMeasurements) && !_.isEmpty(f.orlLossMeasurements[wav]) && !_.isEmpty(f.orlLossMeasurements[wav].RemoteToMain)) {
                            q[wav].ortm = f.orlLossMeasurements[wav].RemoteToMain.loss;
                            q[wav].ortms = f.orlLossMeasurements[wav].RemoteToMain.status;
                            // ortms or o(l)trms ???
                            q[wav].olrtms = f.orlLossMeasurements[wav].RemoteToMain.status;
                            q[wav].ortmn = f.orlLossMeasurements[wav].RemoteToMain.loss < 0;
                        } else if (fgResultsExist.ortm) {
                            q[wav].ortms = "Incomplete";
                        }
                        // OTDR demo -- generate fake fault data
                        q[wav].fmtr = Math.floor(Math.random() * 10);
                        q[wav].frtm = Math.floor(Math.random() * 10);
                        q[wav].fmtrs = (q[wav].fmtr > 0 ? "Failed" : "PassedOrComplete");
                        q[wav].frtms = (q[wav].frtm > 0 ? "Failed" : "PassedOrComplete");
                    });
                    p.wavelengths = q;
                    o.fibers.push(p);
                });
                // get reference wavelengths from nested data
                var rwl = _.uniq(_.flatten(_.map(fg.referenceTestResult, function (tr) {
                    return _.union(_.keys(tr.referenceLossMeasurements), _.keys(tr.referenceOrlLossMeasurements));
                })));
                // get specific reference test types per wavelength from nested data
                _.each(rwl, function (w) {
                    o.has.refWavelength[w] = {};
                    if (o.has.testType.rloss) {
                        o.has.refWavelength[w].rlmtr = _.some(fg.referenceTestResult, function (tr) {
                            return !_.isEmpty(tr.referenceLossMeasurements) && !_.isEmpty(tr.referenceLossMeasurements[w]) && !_.isEmpty(tr.referenceLossMeasurements[w].losses.MainToRemote);
                        });
                        o.has.refWavelength[w].rlrtm = _.some(fg.referenceTestResult, function (tr) {
                            return !_.isEmpty(tr.referenceLossMeasurements) && !_.isEmpty(tr.referenceLossMeasurements[w]) && !_.isEmpty(tr.referenceLossMeasurements[w].losses.RemoteToMain);
                        });
                    }
                    if (o.has.testType.rorl) {
                        o.has.refWavelength[w].romtr = _.some(fg.referenceTestResult, function (tr) {
                            return !_.isEmpty(tr.referenceOrlLossMeasurements) && !_.isEmpty(tr.referenceOrlLossMeasurements[w]) && !_.isEmpty(tr.referenceOrlLossMeasurements[w].losses.MainToRemote);
                        });
                        o.has.refWavelength[w].rortm = _.some(fg.referenceTestResult, function (tr) {
                            return !_.isEmpty(tr.referenceOrlLossMeasurements) && !_.isEmpty(tr.referenceOrlLossMeasurements[w]) && !_.isEmpty(tr.referenceOrlLossMeasurements[w].losses.RemoteToMain);
                        });
                    }
                });
                // get specific reference test types at FG level from above (used to render fiber column headers across FG)
                o.has.testType.rlmtr = _.some(o.has.refWavelength, function (w) {
                    return w.rlmtr;
                });
                o.has.testType.rlrtm = _.some(o.has.refWavelength, function (w) {
                    return w.rlrtm;
                });
                o.has.testType.romtr = _.some(o.has.refWavelength, function (w) {
                    return w.romtr;
                });
                o.has.testType.rortm = _.some(o.has.refWavelength, function (w) {
                    return w.rortm;
                });
                _.each(fg.referenceTestResult, function (f) {
                    // fiber-level props
                    var rp = {
                        fiberGroup: fg.name,
                        fiberId: f.fiberId,
                        sortIndex: f.sortIndex,
                    };
                    // populate status at all reference wavelengths found in FG
                    var rq = {};
                    _.each(o.has.refWavelength, function (fgResultsExist, wav) {
                        rq[wav] = {
                            refWavelength: wav.substr(1)
                        };
                        // reference loss measurements at a given wavelength
                        if (!_.isEmpty(f.referenceLossMeasurements) && !_.isEmpty(f.referenceLossMeasurements[wav]) && !_.isEmpty(f.referenceLossMeasurements[wav].losses.MainToRemote)) {
                            rq[wav].rlmtr = f.referenceLossMeasurements[wav].losses.MainToRemote[0].loss;
                            rq[wav].rlmtrn = f.referenceLossMeasurements[wav].losses.MainToRemote[0].loss < 0;
                            rq[wav].method = lookupDisplayMethod(testMethods, f.referenceLossMeasurements[wav].referenceMethod);
                            rq[wav].timestamp = formatTimestamp(f.referenceLossMeasurements[wav].losses.MainToRemote[0].timestamp);
                        }
                        if (!_.isEmpty(f.referenceLossMeasurements) && !_.isEmpty(f.referenceLossMeasurements[wav]) && !_.isEmpty(f.referenceLossMeasurements[wav].losses.RemoteToMain)) {
                            rq[wav].rlrtm = f.referenceLossMeasurements[wav].losses.RemoteToMain[0].loss;
                            rq[wav].rlrtmn = f.referenceLossMeasurements[wav].losses.RemoteToMain[0].loss < 0;
                            rq[wav].method = lookupDisplayMethod(testMethods, f.referenceLossMeasurements[wav].referenceMethod);
                            rq[wav].timestamp = formatTimestamp(f.referenceLossMeasurements[wav].losses.RemoteToMain[0].timestamp);
                        }
                        // reference orl measurements at a given wavelength
                        if (!_.isEmpty(f.referenceOrlLossMeasurements) && !_.isEmpty(f.referenceOrlLossMeasurements[wav]) && !_.isEmpty(f.referenceOrlLossMeasurements[wav].losses.MainToRemote)) {
                            rq[wav].romtr = f.referenceOrlLossMeasurements[wav].losses.MainToRemote[0].max_loss
                                - f.referenceOrlLossMeasurements[wav].losses.MainToRemote[0].min_loss + 14.6 + 11.2;
                            rq[wav].romtrn = f.referenceOrlLossMeasurements[wav].losses.MainToRemote[0].max_loss < 0;
                        }
                        if (!_.isEmpty(f.referenceOrlLossMeasurements) && !_.isEmpty(f.referenceOrlLossMeasurements[wav]) && !_.isEmpty(f.referenceOrlLossMeasurements[wav].losses.RemoteToMain)) {
                            rq[wav].rortm = f.referenceOrlLossMeasurements[wav].losses.RemoteToMain[0].max_loss
                                - f.referenceOrlLossMeasurements[wav].losses.RemoteToMain[0].min_loss + 14.6 + 11.2;
                            rq[wav].rortmn = f.referenceOrlLossMeasurements[wav].losses.RemoteToMain[0].max_loss < 0;
                        }
                    });
                    rp.refWavelengths = Object.entries(rq).sort((a,b) => {
                        return a[0].substring(1)-b[0].substring(1);
                    });
                    o.refFibers.push(rp);
                });
                if (hasFailedFiber) {
                    o.status = "Failed";
                } else if (hasIncompleteFiber) {
                    o.status = "Incomplete";
                } else {
                    o.status = "PassedOrComplete";
                }
                refactor.push(o);
            });
            return refactor;
        }

        function lookupDisplayMethod(testMethods, testMethod) {
            if (testMethods) {
                for (var i = 0; i < testMethods.length; i++) {
                    var tm = testMethods[i];
                    if (tm.value == testMethod) {
                        return tm.label;
                    }
                }
            }
            // return value if not found in table.
            return testMethod;
        }

        function formatTimestamp(timestamp) {
            var formattedTimestamp = timestamp.substring(5, 7) + "/" + timestamp.substring(8, 10) + "/"
                + timestamp.substring(2, 4) + " " + timestamp.substring(11, 16);
            return formattedTimestamp;
        }

        function getExistingSummaryResults(projectId, orgId) {
            return aerosApi.loadProjectSummary(projectId, orgId)
                .then(function(results) {
                    if (results.data && results.data.testTypes && results.data.hasTestData) {
                        return ["OTDR", "OLTS", "Inspection", "Certification"]
                            .reduce((acc, tool) => {
                                acc[tool] = results.data.testTypes
                                    .filter(element => element.name === tool)
                                    .filter(element => element.hasFailed || element.hasPassedOrComplete
                                        || element.hasIncomplete).length > 0;
                                return acc;
                            }, {"All": results.data.hasTestData.hasTestData});
                    }
                })
        }

        function getProjectInspectionStandards(project, data) {
            var standards = [];
            // This service returns an array of objects of standards
            // Each standard has fibergroup id, specific inspection standard and
            // set of tests with test-specific standards which set on the probe at the time the test is performed

            if (project && project.fiberGroups && project.fiberGroups.length > 0) {
                var ids = project.fiberGroups.map(function (fb) {
                    return fb.id;
                });
                angular.forEach(ids, function (id) {
                    var standard = {};
                    project.fiberGroups.find(function (fb) {
                        var thisFG = project.fiberGroups.find(function (fg) {
                            if (fg.id === id) {
                                return fg ;
                            }
                        });
                        // Each fibegroup could have only one inspection
                        var inspection = thisFG.testSetups.filter(function (test) {
                            return (test.type === "INSPECTION");
                        });
                        if (inspection) {
                            standard.id = id;
                            standard.rule = inspection[0].rule;
                            // Now check if data has a standard rule for the same FB
                            var fgTest = data.find(function (test) {
                                if (test.id === id) {
                                    return test;
                                }
                            });
                            if ((fgTest) && (fgTest.id) && fgTest.testResults && fgTest.testResults.length > 0) {
                                var testStandards = [];
                                // Now let's examine each test and check if it matches the standard defined in aeRos
                                angular.forEach(fgTest.testResults, function (t) {
                                    if (t.inspectionMeasurements.Main.standard) {
                                        var tSt = {};
                                        tSt.standard = t.inspectionMeasurements.Main.standard;
                                        if (standard.rule != tSt.standard) {
                                            // This is standard miss-match
                                            tSt.color = "red";
                                            t.standardMismatch = "red" ;
                                        } else {
                                            tSt.color = "";
                                        }
                                        testStandards.push(tSt);
                                    }
                                    standard.testStandards = testStandards;
                                });
                            }
                        }

                        return standard;
                    });
                    standards.push(standard);
                });
                return standards;
            }
        }

        return service;

    }
})();
